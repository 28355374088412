<template>
  <div>
    <div class="main">

      <DialogConfirmation
        :tag="dialogTag"
        :title="dialogTag === 'add' ? 'Adicionar Promoção' : 'Alterar Promoção'"
        :width="500"
        :show="exibirDialogConfirmacao"
        @close="exibirDialogConfirmacao = false"
        @confirm="onConfirmDialog(dialogTag)"
      >
        <template v-slot:body>
          <p class="mt-5"> {{ mensagemConfirmacao }} </p>
        </template>
      </DialogConfirmation>

      <v-toolbar flat>
        <ButtonMain
          v-if="dadosPromocao"
          :primary="true"
          :text="salvandoPromocao ? 'Alterndo...' : 'Alterar Promoção'"
          @click.native="abrirDialogConfirmacaoAlteracao()"
        />

        <ButtonMain
          v-else
          :primary="true"
          :text="salvandoPromocao ? 'Adicionando...' : 'Adicinar Promoção'"
          @click.native="abrirDialogConfirmacaoCadastro()"
        />

      </v-toolbar>

      <v-row>

        <v-col style="margin-left: 1rem" class="col-112">
          <div class="d-flex">
            <div>
              <h3 class="mt-5">Imagem Promocional</h3>
              <p v-if="!imagemPromocao" style="color: #df8706f7">
                Imagem Promocional ainda não cadastrada
              </p>

              <v-avatar
                :class="{'avatar-imagem': imagemPromocao || imagemBase64 }"
                size="avatarSize"
                color="#f1f2f3"
                rounded
              >

                <div class="imagem-container">
                  <v-img
                    contain
                    max-height="350"
                    width="350"
                    :src="imagemPromocao || imagemNaoEncontrada"
                  ></v-img>

                  <div class="acoes-container">
                    <div class="input-logo-container">
                    </div>
                    <label class="btn-baixar-imagem" for="input-imagem">
                      <v-icon class="mr-2">mdi-pencil</v-icon>
                      {{ imagemPromocao ? 'Alterar Imagem' : 'Adicionar Imagem' }}
                    </label>
                    <input
                      id="input-imagem"
                      class="form-control"
                      type="file"
                      accept="image/png, image/jpeg"
                      @change="onChangeFile($event)"
                    >

                    <a
                      v-if="imagemBase64"
                      target="_blank"
                      class="btn-baixar-imagem"
                      @click="removerImagem()"
                    >
                      <v-icon class="mr-2">mdi-close</v-icon>
                      Remover Imagem
                    </a>

                    <a
                      v-if="imagemPromocao && !imagemBase64"
                      :href="imagemPromocao"
                      target="_blank"
                      class="btn-baixar-imagem"
                    >
                      <v-icon class="mr-2">mdi-arrow-down-bold</v-icon>
                      Baixar Imagem
                    </a>
                  </div>
                </div>
              </v-avatar>
            </div>

            <div class="ml-5">
              <h3 class="mt-5">Descrição Promocional</h3>

              <p v-if="!descricaoPromocao" style="color: #df8706f7">
                Descrição Promocional ainda não cadastrada
              </p>

              <v-textarea
                filled
                counter
                full-width
                class="descricao-promocao mt-4"
                maxlength="250"
                rows="8"
                v-model="descricaoPromocao"
              ></v-textarea>


            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn class="ml-3" to="/lista-parceiros">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import parceiroService from '@/services/ParceiroService'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation.vue'

export default {
  components: {
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      salvandoPromocao: false,
      exibirDialogConfirmacao: false,
      imagemPromocao: "",
      descricaoPromocao: "",
      imagemBase64: "",
      mensagemConfirmacao: "",
      dialogTag: "",
      imagemNaoEncontrada: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=x&backgroundColor=808080`,
      imagemUpload: null,
      dadosPromocao: null,
    }
  },

  created() {
    this.receberPromocaoParceiro();
  },

  methods: {
    receberPromocaoParceiro() {
      const parceiroId = this.$route.params.id;
      parceiroService.promocaoDoParceiro(parceiroId)
        .then(({ data }) => {
          this.dadosPromocao = data;
          this.imagemPromocao = data.arquivo || "";
          this.descricaoPromocao = data.descricao || "";
        })
        .catch(() => {
          this.imagemPromocao = "";
          this.descricaoPromocao = "";
        })
    },

    onChangeFile(file) {
      if(file && Array.from(file.srcElement.files).length) {
        const logo = file.srcElement.files[0]
        const reader = new FileReader()

        reader.onload = (e) => {
          this.imagemBase64 = e.target.result;
          this.imagemPromocao = this.imagemBase64;

          this.imagemUpload = {
            arquivo: this.imagemBase64.split(';base64,')[1],
            extensao: this.imagemBase64.split(';')[0].split('/')[1],
          }
        }

        reader.readAsDataURL(logo);
      }
    },

    abrirDialogConfirmacaoAlteracao() {
      this.dialogTag = 'edit';
      this.mensagemConfirmacao = 'Deseja alterar a promoção deste parceiro?';

      if(!this.imagemBase64 && (this.dadosPromocao.descricao === this.descricaoPromocao)) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Aviso',
          text: 'Nenhuma alteração detectada',
        });

        return;
      }

      this.exibirDialogConfirmacao = true
    },

    abrirDialogConfirmacaoCadastro() {
      this.dialogTag = 'add';
      this.mensagemConfirmacao = 'Deseja adicionar promoção para este parceiro?';

      if(!this.imagemBase64 && !this.descricaoPromocao) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Aviso',
          text: 'Nenhuma alteração detectada',
        });

        return;
      }

      if(!this.imagemBase64 && this.descricaoPromocao) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Aviso',
          text: 'A descição promocional não pode ser adicionada sem a imagem promocional',
        });

        return;
      }

      if(this.imagemBase64 && (!this.descricaoPromocao || !this.descricaoPromocao.trim().length)) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Aviso',
          text: 'A imagem promocional não pode ser adicionada sem a descrição promocional',
        });

        return;
      }

      this.exibirDialogConfirmacao = true
    },

    onConfirmDialog(tag) {
      const body = {
        parceiro: Number(this.$route.params.id),
        arquivos: [{
          arquivo: this.imagemUpload ? this.imagemUpload.arquivo : null,
          descricao: this.descricaoPromocao,
          extensao: this.imagemUpload ? this.imagemUpload.extensao : null,
          idTipo: 4,
          descricaoTipo: 'IMAGEM PROMOCIONAL',
        }],
      }

      if(tag === 'edit') {
        this.alterarPromocao(body);
      } else if (tag === 'add') {
        this.cadastrarImagem(body);
      }
    },

    alterarPromocao(body) {
      if(!this.descricaoPromocao.trim().length) {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'A apresentação promocional nãp pode ser vazia',
        });
        return;
      }

      if(this.imagemBase64) {
        this.salvandoPromocao = true;
        this.cadastrarImagem(body);
      }

      if(this.dadosPromocao.descricao !== this.descricaoPromocao) {
        this.salvandoPromocao = true;
        const bodyDescricao = {
          arquivo: null,
          descricao: this.descricaoPromocao,
          idArquivo: this.dadosPromocao.idArquivo,
          idParceiro: Number(this.$route.params.id),
          idTipo: 4
        }

        parceiroService
          .atualizarPromocaoParceiro(this.dadosPromocao.idArquivo, bodyDescricao)
          .then(() => {
            this.$root.vtoast.show({
              status: 'success',
              title: 'Cadastrado',
              text: 'Descrição Promocional alterada com sucesso',
            });

            this.salvandoPromocao = false;
            this.exibirDialogConfirmacao = false;
            this.receberPromocaoParceiro();
          })
          .catch((err) => {
            this.salvandoPromocao = false;
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
      }
    },

    cadastrarImagem(body) {
      parceiroService
        .cadastrarDocumentosParceiro(body)
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            title: 'Cadastrado',
            text: 'Imagem Promocional alterada com sucesso',
          });

          this.imagemUpload = null;
          this.imagemBase64 = '';
          this.salvandoPromocao = false;
          this.exibirDialogConfirmacao = false;
          this.receberPromocaoParceiro();
        })
        .catch((err) => {
          this.salvandoPromocao = false;
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        });
    },

    removerImagem() {
      setTimeout(() => {
        this.imagemPromocao = this.dadosPromocao ? this.dadosPromocao.arquivo : null;
        this.imagemBase64 = '';
        this.imagemUpload = null;
      }, 100);
    }
  },
}
</script>

<style scoped>

  .avatar-imagem {
    margin-top: 2.6rem;
  }
  .descricao-promocao {
    overflow-wrap: break-word;
    width: 30rem;
  }
  .footer-pagina {
    margin-top: 2rem;
  }

  .imagem-container {
    position: relative;
    height: 15.625rem;
  }

  .imagem-container:hover .acoes-container {
    opacity: 1;
  }

  .imagem-container:hover .acoes-container .btn-baixar-imagem {
    opacity: 1;
  }

  .imagem-container .acoes-container {
    background-color: rgba(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .imagem-container .acoes-container .btn-baixar-imagem {
    background-color: #fff;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.4rem 1.5rem;
    border: 0;
    border-radius: 1rem;
    text-decoration: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .imagem-container .acoes-container .btn-baixar-imagem:active {
    transform: scale(0.98);
  }

  .input-logo-container {
    display: none; position: absolute;
    top: 50%; left: 50%; z-index: 100;
    transform: translate(-50%, -50%);
    transition: display 0.2s ease;
  }

  .imagem-container .acoes-container label {
    background-color: #fff;
    display: flex; justify-content: center; align-items: center;
    font-size: 12px; padding: 0.3rem 0.5rem;
    border: none; border-radius: 0.3rem;
    cursor: pointer;
    margin-bottom: 0.3rem;
  }

  .imagem-container .acoes-container input {
    position: absolute; width: 100%;
    z-index: -1; opacity: 0;
  }
</style>
