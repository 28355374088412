<template>
  <div>
    <div class="main">
      <v-row>
        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosParceiro()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará os dados deste Parceiro. Deseja continuar?
            </div>
          </template>
        </DialogConfirmation>

        <DialogConfirmation
          tag="edit"
          title="Alterar Logo do Parceiro"
          :show="exibirDialogAvisoLogo"
          @confirm="salvarLogoParceiro()"
          @close="exibirDialogAvisoLogo = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará a Logo deste Parceiro. Deseja continuar?
            </div>
          </template>
        </DialogConfirmation>

        <DialogConfirmation
          persistente
          tag="add"
          title="Localização"
          :show="exibirDialogMapa"
          :width="950"
          @confirm="adicionarLocalizacao()"
          @close="fechaDialogLocalizacao()"
        >
          <template v-slot:body>
            <v-lazy>
              <MapaMain
                v-if="exibirDialogMapa"
                :keyMapa="keyMapa"
                :coordenadas="{ latitude: Number(dadosParceiro.latitude), longitude: Number(dadosParceiro.longitude) }"
                @atualizarCordenadas="onChangeCoordenada($event)"
              ></MapaMain>
            </v-lazy>
          </template>
        </DialogConfirmation>


        <v-col style="margin-top: 4.7rem" md="3" order-md="2">
          <div v-if="status"
            style="width: 250px"
            class="text-center"
          >
            <StatusChip
              class="mb-1"
              :color="getColor(status)"
            >
              {{ status }}
            </StatusChip
            >
          </div>

          <v-avatar size="avatarSize" color="#f1f2f3" rounded>

            <div class="imagem-container">
              <v-img
                contain
                height="100%"
                width="250"
                :src="logoParceiro || avatar"
              ></v-img>

              <div class="acoes-container">

                <div class="input-logo-container">
                </div>
                <label class="btn-baixar-imagem" for="input-foto-perfil">
                  <v-icon class="mr-2">mdi-pencil</v-icon>
                  {{ dadosParceiro.logo ? 'Alterar Logo' : 'Adicionar Logo' }}
                </label>
                <input
                  id="input-foto-perfil"
                  class="form-control"
                  type="file"
                  accept="image/png, image/jpeg"
                  @change="onChangeFileLogo($event)"
                >

                <a
                  v-if="logoBase64"
                  target="_blank"
                  class="btn-baixar-imagem"
                  @click="removerLogo()"
                >
                  <v-icon class="mr-2">mdi-close</v-icon>
                  Remover Logo
                </a>

                <a
                  v-if="dadosParceiro.logo && !logoBase64"
                  :href="dadosParceiro.logo"
                  target="_blank"
                  class="btn-baixar-imagem"
                >
                  <v-icon class="mr-2">mdi-arrow-down-bold</v-icon>
                  Baixar Logo
                </a>
              </div>
            </div>
          </v-avatar>

          <v-btn
            v-if="logoBase64"
            style="width: 16rem;"
            class="mt-2 mb-2 d-flex"
            @click="exibirDialogAvisoLogo = true"
          >
            {{ salvandoLogo ? 'Salvando...' : 'Salvar Logo' }}
          </v-btn>

          <div class="cores-tema mt-3">
            <div class="cor-container">
              <h3>Cor Primária</h3>

              <label v-if="!dadosParceiro.corPrimaria" class="orange--text d-flex mb-3 mt-2">
                Cor primária ainda não definida pelo Parceiro
              </label>

              <v-btn
                v-if="!dadosParceiro.corPrimaria"
                style="width: 100%;"
                class="mb-2"
                @click="editarCorPrimaria = !editarCorPrimaria"
              >
                {{ editarCorPrimaria ? 'Desabilitar Edição' : 'Habilitar Edição'}}
              </v-btn>

              <v-color-picker
                ref="colorPickerCorPrimaria"
                hide-canvas
                hide-mode-switch
                class=""
                mode="hexa"
                :disabled="!editarCorPrimaria"
                :value="dadosParceiro.corPrimaria"
                @update:color="corPrimariaParceiro = $event.hexa"
              ></v-color-picker>
            </div>
          </div>

          <v-divider></v-divider>

          <div  class="cores-tema mt-3">
            <div class="cor-container">
              <h3>Cor Secundária</h3>

              <label v-if="!dadosParceiro.corSecundaria" class="orange--text d-flex mb-3 mt-2">
                Cor secundária ainda não definida pelo Parceiro
              </label>

              <v-btn
                v-if="!dadosParceiro.corSecundaria"
                style="width: 100%;"
                class="mb-2"
                @click="editarCorSecundaria = !editarCorSecundaria"
              >
                {{ editarCorSecundaria ? 'Desabilitar Edição' : 'Habilitar Edição'}}
              </v-btn>

              <v-color-picker
                :key="51"
                ref="colorPickerCorSecundaria"
                hide-canvas
                hide-mode-switch
                class=""
                mode="hexa"
                :disabled="!editarCorSecundaria"
                :value="dadosParceiro.corSecundaria"
                @update:color="corSecundariaParceiro = $event.hexa"
                ></v-color-picker>
            </div>
          </div>
        </v-col>

        <v-col offset-sm="12" md="9">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados da Empresa" />
          <FormField
            :fields="fields"
            :validate="keyValidacaoFormParceiro"
            @clickBotao="onClickBotaoForm($event)"
          ></FormField>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end" offset-sm="12" md="9">
          <ButtonMain
            class="primary mr-5 mb-5"
            style="width: 12rem"
            animacao="spin"
            :text="alterandoDadosParceiro ?  'Alterando...' : 'Alterar'"
            :icone="alterandoDadosParceiro ? 'autorenew' : ''"
            :desabilitar="alterandoDadosParceiro"
            @click.native="exibirDialogAvisoEdicao = true"
          ></ButtonMain>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn class="ml-3" to="/lista-parceiros">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation.vue'
import StatusChip from "@/components/atoms/StatusChip";
import parceiroService from '@/services/ParceiroService'
import cepService from '@/services/CepService'
import utilService from '@/services/UtilService'
import MapaMain from '../../components/molecules/mapa/MapaMain.vue';

export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation,
    MapaMain
    //InputField,
},

  data() {
    return {
      keyValidacaoFormParceiro: 0,
      keyDialogMapa: 30,
      keyMapa: 4,
      exibirDialogMapa: false,
      dialog: false,
      exibirDialogAvisoEdicao: false,
      exibirDialogAvisoLogo: false,
      salvandoLogo: false,
      alterandoDadosParceiro: false,
      criandoFormulario: false,
      editarCorPrimaria: false,
      editarCorSecundaria: false,
      corSecundariaParceiro: "",
      corPrimariaParceiro: "",
      corSecundariaParceiro: "",
      avatar: "",
      status: "",
      logoParceiro: "",
      logoBase64: "",
      nomeParceiro: "",
      enderecoCep: null,
      cashbackMinimo: 0,
      descontoMinimo: 0,
      dadosParceiro: {},
      logoUpload: null,
      coordenadasEdicao: null,
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
        desconto: `O desconto deve ser entre 0% e 100%`,
        cashback: `O cashback deve ser entre 0% e 100%`,
        casasDecimais: `Insira apenas 2 casas decimais`,
      },
      fields: {
        nome: {
          label: 'Nome Fantasia',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        cnpj: {
          label: 'CNPJ',
          valueField: null,
          value: '',
          cols: 6,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##.###.###/####-##',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 14 || this.validadores.invalido,
          ],
        },
        inscricaoMunicipal: {
          label: 'Inscrição Municipal',
          valueField: null,
          cols: 3,
          field: 'InputField',
        },
        inscricaoEstadual: {
          label: 'Inscrição Estadual',
          valueField: null,
          cols: 3,
          field: 'InputField'
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(value) || this.validadores.invalido,
          ],
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: null,
          cols: 4,
          value: '',
          field: 'InputMaskField',
          maskFormat: '(##) #####-####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 10 || this.validadores.invalido,
          ],
        },
        whatsapp: {
          label: 'Whatsapp',
          valueField: null,
          value: '',
          cols: 4,
          maskFormat: '(##) #####-####',
          field: 'InputMaskField',
          rules: [
            (value) => (!value || value.replace(/[^\d]/g, '').length >= 10) || this.validadores.invalido,
          ],
        },
        telefone0800: {
          label: 'Telefone 0800',
          valueField: null,
          value: '',
          cols: 4,
          maskFormat: '#### ### ####',
          field: 'InputMaskField',
          rules: [
            (value) => (!value || value.replace(/[^\d]/g, '').length >= 11) || this.validadores.invalido,
          ],
        },
        instagram: {
          label: 'Instagram',
          valueField: null,
          value: '',
          cols: 5,
          field: 'InputField',
        },

        enderecoComercial: {
          cols: 12,
          label: 'Endereço Comercial',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '##.###-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 8 || this.validadores.invalido,
          ],
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: null,
          cols: 6,
          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        idUf: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        idCidade: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          key: 107,
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        pontoReferencia: {
          label: 'Ponto de Referencia',
          value: null,
          valueField: null,
          cols: 4,
          field: 'InputField',
        },

        localizacao: {
          text: 'Adicionar Localização',
          cols: 4,
          key: 125,
          field: 'ButtonMain',
          evento: 'adicionar-localizacao',
          estilo: 'height: 3.4rem; margin-top: 0.7rem; margin-left: 0.7rem;',
          corIcone: '#4caf50',
          tipoBotao: 'button',
          erroValidacao: '',
        },

        informacoesFinanceirasDivisor: {
          cols: 12,
          label: 'Informações Financeiras',
          field: 'DividerMain'
        },
        desconto: {
          label: 'Desconto do Associado',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField',
          disabled: true,
          rules: [],

        },
        cashback: {
          label: 'Cashback do CAAMA',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField',
          disabled: true,
          rules: [],
        },
      },
      listaUfs: [],
    }
  },

  created() {
    this.receberDadosParceiro();
    this.receberUFs();
  },

  methods: {
    receberDadosParceiro() {
      parceiroService
        .parceiroPorId(this.$route.params.id)
        .then(({ data }) => {
          this.dadosParceiro = data;
          this.logoParceiro = data.logo;
          this.status = data.descricaoStatus;
          this.corPrimariaParceiro = data.corPrimaria;
          this.corSecundariaParceiro = data.corSecundaria;
          this.popularFormulario(data);

          if(data.latitude && data.longitude) {
            this.fields.localizacao.icone = 'checkbox-marked-circle';
            this.fields.localizacao.text = 'Alterar Localização';
          }

          if(!data.logo) {
            this.avatar = `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${data.nome}`;
          }
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        });
    },

    popularFormulario(data) {
      this.criandoFormulario = true;
      Object.entries(data).map(([key, value]) => {
          if(key === 'corPrimaria' && value) {
            this.editarCorPrimaria = true;
          }

          if(key === 'corSecundaria' && value) {
            this.editarCorSecundaria = true;
          }

        if (this.fields[key]) {
          if(key === 'desconto') {
            this.fields[key].label = 'Desconto do Associado (%)';
          }

          if(key === 'cashback') {
            this.fields[key].label = 'Cashback da CAAMA (%)';
          }

          this.fields[key].valueField = value;
        }
      })
    },

    verificarSeHaCampoObrigatorioNulo(campos) {
      let campoInvalido = false;
      for(const value of Object.values(campos)) {
        const valorCampo = value.value;
        const regras = value.rules;
        let erroCampo = false;

        if(regras && regras.length) {
          for(const regra of regras) {
            if(
              regra(valorCampo) === this.validadores.obrigatorio
              || regra(valorCampo) === this.validadores.invalido
            ) {
              erroCampo = true;
              break;
            }
          }
        }

        if(erroCampo) {
          campoInvalido = true;
          break;
        };
      }

      return campoInvalido;
    },

    alterarDadosParceiro() {
      this.exibirDialogAvisoEdicao = false;

      if (this.verificarSeHaCampoObrigatorioNulo(this.fields)) {
        ++this.keyValidacaoFormParceiro;
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      if (!this.dadosParceiro.latitude || !this.dadosParceiro.longitude) {
        ++this.keyValidacaoFormParceiro;
        this.fields.localizacao.erroValidacao = 'Campo obrigatório';
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      const body = {
        contratoLido: this.dadosParceiro.contratoLido,
        corPrimaria: this.editarCorPrimaria ? this.corPrimariaParceiro : null,
        corSecundaria: this.editarCorSecundaria ? this.corSecundariaParceiro : null,
        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        cnpj: this.fields.cnpj.value,
        complemento: this.fields.complemento.value,
        email: this.fields.email.value,
        cidadeEndereco: this.fields.idCidade.value,
        logradouro: this.fields.logradouro.value,
        nome: this.fields.nome.value,
        numero: this.fields.numero.value,
        pontoReferencia: this.fields.pontoReferencia.value,
        longitude: String(this.dadosParceiro.longitude),
        latitude: String(this.dadosParceiro.latitude),

        telefone: this.fields.telefone.value,
        telefone0800: this.fields.telefone0800.value,
        inscricaoMunicipal: this.fields.inscricaoMunicipal.value,
        inscricaoEstadual: this.fields.inscricaoEstadual.value,
        whatsapp: this.fields.whatsapp.value,
        instagram: this.fields.instagram.value,
        cashback: Number(this.dadosParceiro.cashback),
        desconto: Number(this.dadosParceiro.desconto),
      }

      parceiroService
        .atualizarParceiro(this.dadosParceiro.id, body)
        .then(() => {
          this.receberDadosParceiro();

          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Parceiro atualizado com sucesso.',
          });
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    getColor(status) {
      status = status.toUpperCase();
      if (status === "ATIVO") return "green";
      if (status === "INATIVO") return "red";
      if (status === "EM ANALISE") return "orange";
      return "";
    },

    verificarCep(cep) {
      cepService.buscarCep(cep)
        .then(({ data }) => {
          if (data.erro) {
            this.fields.bairro.valueField = null;
            this.fields.logradouro.valueField = null;
            this.fields.idUf.valueField = null;
            this.fields.idUf.value = null;
            this.fields.idCidade.valueField = null;
            this.fields.idCidade.value = null;
            return;
          }

          this.fields.bairro.valueField = data.bairro;
          this.fields.logradouro.valueField = data.logradouro;
          this.enderecoCep = data;
        });
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf_venda(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    receberUFs() {
      utilService.ufs_venda().then(({ data }) => {
        this.listaUfs = data;
        this.fields.idUf.items = data;
      })
    },

    onClickBotaoForm(evento) {
      if(evento.evento === 'adicionar-localizacao') {
        this.keyDialogMapa++;
        this.exibirDialogMapa = true;
      }
    },

    fechaDialogLocalizacao() {
      this.coordenadasEdicao = null;
      this.keyMapa++;
      this.keyDialogMapa++;
      this.exibirDialogMapa = false;
    },

    onChangeCoordenada(evento) {
      this.coordenadasEdicao = evento;
    },

    adicionarLocalizacao() {
      if(!this.coordenadasEdicao || Number(this.coordenadasEdicao.latitude) === 0 || Number(this.coordenadasEdicao.longitude) === 0) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Coordenadas Inválidas!',
          text: 'As coordenadas [0, 0] não são válidas',
        });
        return;
      }

      this.dadosParceiro.latitude = Number(this.coordenadasEdicao.latitude);
      this.dadosParceiro.longitude = Number(this.coordenadasEdicao.longitude);
      this.fields.localizacao.icone = 'checkbox-marked-circle';
      this.fields.localizacao.text = 'Alterar Localização';
      this.fields.localizacao.erroValidacao = '';
      this.exibirDialogMapa = false;
    },

    onChangeFileLogo(file) {
      if(file) {
        const logo = file.srcElement.files[0]
        const reader = new FileReader()

        reader.onload = (e) => {
          this.logoBase64 = e.target.result;
          this.logoParceiro = this.logoBase64;

          this.logoUpload = {
            arquivo: this.logoBase64.split(';base64,')[1],
            descricaoTipo: null,
            extensao: this.logoBase64.split(';')[0].split('/')[1],
            idTipo: 3,
          }
        }

        reader.readAsDataURL(logo);
      }
    },

    salvarLogoParceiro() {
      const body = {
        parceiro: Number(this.$route.params.id),
        arquivos: [this.logoUpload],
      }

      this.salvandoLogo = true;
      parceiroService
        .cadastrarDocumentosParceiro(body)
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            title: 'Cadastrado',
            text: 'Logo adicionada com sucesso',
          });

          this.exibirDialogAvisoLogo = false;
          this.logoUpload = null;
          this.logoBase64 = '';
          this.salvandoLogo = false;
          this.receberDadosParceiro();
        })
        .catch((err) => {
          this.salvandoLogo = false;
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        });
    },

    removerLogo() {
      setTimeout(() => {
        this.logoParceiro = this.dadosParceiro.logo;
        this.logoBase64 = '';
        this.logoUpload = null;
      }, 100);
    }
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    'fields.idUf.value': function (id) {
      this.fields.idCidade.key++;
      this.fields.idCidade.valueField = null;
      this.fields.idCidade.value = null;
      this.receberCidadesPorUF(id, 'idCidade')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.idUf.valueField = uf.id
          this.fields.idUf.value = uf.id

          utilService.cidadesPorUf_venda(uf.id).then(({ data }) => {
            this.fields.idCidade.items = data

            this.fields.idCidade.items.filter((cidade) => {
              if (cidade.nome === valor.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) {
                this.fields.idCidade.value = cidade.id
                this.fields.idCidade.valueField = cidade.id
              }
            });
          })
        }
      });
    }
  },
}
</script>

<style scoped>
  .footer-pagina { margin-top: 2rem;}

  .cores-tema {
    display: flex; justify-content: center;
    width: 15.625rem;
  }

  .cores-tema .cor-container {
    display: flex; flex-direction: column;
    align-items: flex-start; width: 100%;
  }

  .cores-tema .cor-container label {margin-bottom: 0.3rem;}

  .cores-tema .cor-primaria,
  .cores-tema .cor-secundaria {
    display: flex; justify-content: center;
    align-items: center; flex-direction: column;
    width: 100%; height: 2.8rem;
    border-radius: 0.3rem; box-shadow: 0 3px 6px -3px rgb(0 0 0 / 10%);
  }

  .imagem-container {
    position: relative;
    height: 15.625rem;
  }

  .imagem-container:hover .acoes-container {
    opacity: 1;
  }

  .imagem-container:hover .acoes-container .btn-baixar-imagem {
    opacity: 1;
  }

  .imagem-container .acoes-container {
    background-color: rgba(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .imagem-container .acoes-container .btn-baixar-imagem {
    background-color: #fff;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.4rem 1.5rem;
    border: 0;
    border-radius: 1rem;
    text-decoration: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .imagem-container .acoes-container .btn-baixar-imagem:active {
    transform: scale(0.98);
  }

  .input-logo-container {
    display: none; position: absolute;
    top: 50%; left: 50%; z-index: 100;
    transform: translate(-50%, -50%);
    transition: display 0.2s ease;
  }

  .imagem-container .acoes-container label {
    background-color: #fff;
    display: flex; justify-content: center; align-items: center;
    font-size: 12px; padding: 0.3rem 0.5rem;
    border: none; border-radius: 0.3rem;
    cursor: pointer;
    margin-bottom: 0.3rem;
  }

  .imagem-container .acoes-container input {
    position: absolute; width: 100%;
    z-index: -1; opacity: 0;
  }

</style>
