<template>
  <div id="mapa-container">
    <div id="mapa-locizacao"></div>
  </div>
</template>

<script>
  import * as Leaflet from 'leaflet'

  export default {
    props: {
      keyMapa: {
        type: Number,
        default: 0,
      },
      coordenadas: {
        type: Object,
        default: () => ({
          longitude: null,
          latitude: null
        })
      }
    },

    data() {
      return {
        map: null,
        icone: null,
        center: null,
        marcador: null,
        zoom: 6,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }
    },

    mounted() {
      const container = Leaflet.DomUtil.get("mapa-locizacao");
      if (container != null) {
        container._leaflet_id = null;
      }

      this.center = this.coordenadas.longitude && this.coordenadas.latitude
        ? [this.coordenadas.latitude, this.coordenadas.longitude]
        : [-4.9609, -45.27445]

      this.icone = Leaflet.icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      }),

      this.marcador = this.coordenadas.longitude && this.coordenadas.latitude
          ? Leaflet.marker([this.coordenadas.latitude, this.coordenadas.longitude], { icon: this.icone })
          : Leaflet.marker([0, 0], { icon: this.icone })

      this.initMap();
    },

    methods: {
      initMap: function () {
        this.map = Leaflet.map('mapa-locizacao', {
          center: this.center,
          zoom: 6,
        })

        if(this.coordenadas.longitude && this.coordenadas.latitude) {
          this.coordenadas.latitude = this.marcador._latlng.lat;
          this.coordenadas.longitude = this.marcador._latlng.lng;
          this.$emit('atualizarCordenadas', this.coordenadas)

          this.marcador.addTo(this.map)
        }

        this.map.on('click', (mapa) => {
          this.marcador.setLatLng(mapa.latlng);
          this.marcador.addTo(this.map);

          this.coordenadas.latitude = this.marcador._latlng.lat;
          this.coordenadas.longitude = this.marcador._latlng.lng;
          this.$emit('atualizarCordenadas', this.coordenadas)
        });

        Leaflet.tileLayer(this.url, {
          attribution: this.attribution,
          maxZoom: 18,
          minZoom: 3,
        }).addTo(this.map)
      }
    },
  }
</script>

<style scoped>
  #mapa-locizacao {
    position: relative;
    z-index: 0;
    height: 520px;
    width: 100%;
    overflow: hidden;
  }
</style>
