<template>
  <div>
    <DialogConfirmation
      :width="tagDialog == 'add' ? 850 : 500"
      :show="exibirDialogConfirmacao"
      :tag="tagDialog"
      :title="tagDialog === 'delete' ? 'Desvincular Área' : 'Vincular Área'"
      @close="fecharDialog()"
      @confirm="alterarStatusAreaAtuacao()"
    >
      <template v-slot:body>
        <div v-if="tagDialog === 'add'">
          <v-autocomplete
            class="mt-5"
            v-model="itemSelecionado"
            label="Áreas do Parceiro"
            item-text="descricao"
            item-value="idArea"
            outlined
            return-object
            :items="areasNaoVinculadas"
          >
          </v-autocomplete>
        </div>
        <p class="mt-5" v-if="tagDialog === 'activate'">
          Deseja vincular a área {{ itemSelecionado.descricao }} à este parceiro?
        </p>

        <p class="mt-5" v-if="tagDialog === 'delete'">
          Deseja desvincular a área {{ itemSelecionado.descricao }} deste parceiro?
        </p>
      </template>
    </DialogConfirmation>

    <CrudTable
      tabelaKey="id"
      :headers="headers"
      :items="areasVinculadas"
      :pagination="pagination"
      :loading="loading"
    >
      <template
        v-slot:top
      >
        <v-toolbar flat>
          <ButtonMain
            :primary="true"
            text="Vincular Área"
            @click.native="abrirDialogVincularArea()"
          />
        </v-toolbar>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
      >
        <v-btn
          small
          color="green"
          class="mr-2"
          :disabled="item.ativo"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'activate'
            itemSelecionado = item
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-check-circle
              </v-icon>
            </template>
            <span>Vincular Área</span>
          </v-tooltip>
        </v-btn>
        <v-btn
          small
          color="red"
          class="white--text"
          :disabled="!item.ativo"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'delete'
            itemSelecionado = item
          "
        >
          <v-tooltip top>
            <template small v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-close-circle
              </v-icon>
            </template>
            <span>Desativar Área</span>
          </v-tooltip>
        </v-btn>
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        <span :class="item.ativo ? '' : 'area-desviculada'">{{ item.descricao }}</span>
      </template>
    </CrudTable>

    <div class="footer-pagina">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 mt-5" :to="rotaDeVolta">
          <v-icon small>mdi-arrow-left-thin </v-icon>
          Voltar
        </v-btn></v-toolbar
      >
    </div>

  </div>
</template>

<script>
import CrudTable from '../../components/molecules/tables/CrudTable';
import ButtonMain from '../../components/atoms/buttons/ButtonMain';
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation';
import parceiroService from '../../services/ParceiroService';

export default {
  data() {
    return {
      rotaDeVolta: '/lista-parceiros',
      tagDialog: '',
      areasVinculadas: [],
      areasNaoVinculadas: [],
      loading: false,
      exibirDialogConfirmacao: false,
      itemSelecionado: {},
      headers: [
        { text: 'Descrição', value: 'descricao', sortable: false },
        { text: 'Status', value: 'status', align: 'center', width: 160, sortable: false },
        { text: 'Ações', value: 'actions', width: 150, sortable: false }
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  components: {
    CrudTable,
    ButtonMain,
    DialogConfirmation
  },

  created() {
    this.receberAreasVinculadasAoParceiro();
  },

  methods: {
    receberAreasVinculadasAoParceiro() {
      parceiroService
        .areasAtuacaoVinculadasAoParceiro(this.$route.params.id)
        .then(({ data }) => {
          this.areasVinculadas = data.map(item => {
            return {
              ...item,
              status: item.ativo ? 'VINCULADA' : 'DESVINCULADA'
            }
          });
        })
    },

    fecharDialog() {
      this.exibirDialogConfirmacao = false
      this.itemSelecionado = {};
    },

    abrirDialogVincularArea() {
      this.exibirDialogConfirmacao = true;
      this.tagDialog = 'add';
      this.receberAreasNaoVinculadasAoParceiro(this.$route.params.id);
    },

    receberAreasNaoVinculadasAoParceiro(empresaId) {
      parceiroService
        .areasAtuacaoNaoVinculadasPorParceiro(empresaId)
        .then(({ data }) => {
          this.areasNaoVinculadas = data
        });
    },

    alterarStatusAreaAtuacao() {
      this.exibirDialogConfirmacao = false
      if (this.tagDialog === 'add') {
        if (Object.keys(this.itemSelecionado).length) {
          parceiroService
            .vincularAreaParceiro({
              area: this.itemSelecionado.idArea,
              idVendedor: this.$route.params.id,
            })
            .then(() => {
              this.itemSelecionado = {}
              this.receberAreasVinculadasAoParceiro()
              this.$root.vtoast.show({
                status: 'success',
                text: 'Área vinculada com sucesso.',
                title: 'SUCESSO!'
              })
            })
            .catch((err) => {
              this.itemSelecionado = {}
              this.$root.vtoast.show({
                status: 'error',
                text: err.response?.data.mensagemUsuario,
                title: err.response?.data.titulo
              })
              this.receberAreasVinculadasAoParceiro()
            })
        } else {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Nenhum item selacionado',
          })
        }
      }
      if (this.tagDialog === 'activate' || this.tagDialog === 'delete') {
        const body = {
          idVendedor: this.$route.params.id,
          area: this.itemSelecionado.idArea,
          ativo: this.tagDialog === 'activate' ? true : false,
        }

        parceiroService
          .atualizarStatusVinculoArea(body)
          .then(() => {
            this.receberAreasVinculadasAoParceiro()

            this.$root.vtoast.show({
              status: 'success',
              text: 'Área vinculada com sucesso.',
              title: 'SUCESSO!'
            })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
            this.receberAreasVinculadasAoParceiro()
          })
      }

      this.itemSelecionado = {}
    },
  },
}
</script>

<style>
  .area-desviculada {
    color: #9e9e9e ;
    text-decoration: line-through;
  }

  .footer-pagina { margin-top: 2rem;}
</style>
