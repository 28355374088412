<template>
  <div>
    <v-tabs v-model="tab" grow>
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosParceiro from './DadosParceiro'
import Socios from './Socios'
import Documentos from './Documentos'
import ListarAreas from './ListarAreas.vue'
import InformacoesPromocionais from './InformacoesPromocionais.vue'

export default {
  components: {
    DadosParceiro,
    Socios,
    Documentos,
    ListarAreas,
    InformacoesPromocionais,
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'Tab 1 Content',
          component: 'DadosParceiro'
        },
        {
          tab: 'Sócios',
          content: 'Tab 2 Content',
          component: 'Socios'
        },
        {
          tab: 'Documentos',
          content: 'Tab 3 Content',
          component: 'Documentos'
        },
        {
          tab: 'Áreas de Atuação ',
          content: 'Tab 4 Content',
          component: 'ListarAreas'
        },
        {
          tab: 'Informações Promocionais ',
          content: 'Tab 5 Content',
          component: 'InformacoesPromocionais'
        }
      ]
    }
  }
}
</script>

<style scoped>
.ajuste {
  width: 80%;
}
</style>
