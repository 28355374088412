<template>
  <div>
    <v-dialog v-model="dialogDocumentosAberto" width="700">
      <v-card>
        <v-toolbar
          class="blue white--text"
          elevation="1"
          dense
        >
          <v-toolbar-title>DOCUMENTOS - {{ nomeSocio }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDocumentosAberto = false"
            color="white"
            icon
            class="hidden-xs-only"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <div v-if="listaDocumentosSocio.length > 0">
          <div v-for="(arquivo, index) in listaDocumentosSocio" :key="index">
            <div>
              <v-hover>
                <template v-slot:default>
                  <v-card
                    elevation="2"
                    class="mx-auto pa-2 d-flex justify-space-between pl-5 pr-5"
                  >
                    <p>{{ arquivo.descricaoTipo }}</p>
                    <div>
                      <a style="text-decoration: none"
                        :href="arquivo.arquivo"
                        target="_blank"
                      >
                        VER
                      </a>
                    </div>
                  </v-card>
                </template>
              </v-hover>
            </div>
          </div>
        </div>

        <div v-else>
          <v-hover>
            <template v-slot:default>
              <v-card elevation="2" class="mx-auto pa-2 text-center">
                <p class="mt-2">Nenhum documento encontrado</p>
              </v-card>
            </template>
          </v-hover>
        </div>
      </v-card>
    </v-dialog>

    <DialogConfirmation
      :tag="tagDialogSocio"
      :title="tagDialogSocio === 'add' ? 'Adicionar Sócio' : 'Editar Sócio'"
      :scrollable="true"
      :width="750"
      :show="exibirDialogAdicionarSocio"
      :key="keyDialogAdicionarSocio"
      @close="fecharDialogSocio()"
      @confirm="confirmarAcaoDialogSocio()"
    >
      <template v-slot:body>
        <v-form class="form-fields fields">
          <FormField
            :validate="keyFormValidacao"
            :fields="fields"
          ></FormField>
        </v-form>
      </template>
    </DialogConfirmation>

    <DialogConfirmation
      :tag="'add'"
      :title="'Adicionar Documento'"
      :scrollable="true"
      :width="750"
      :show="exibirDialogAdicionarDocumento"
      :key="keyDialogAdicionarDocumento"
      @close="fecharDialogDocumento()"
      @confirm="adicionarDocumento()"
    >
      <template v-slot:body>
        <v-form class="form-fields fields">
          <p class="ml-3 pt-2 black--text">
            Apenas tipos de documentos ainda não cadastrados para esse Sócio
            ficaram disponíveis para seleção.
          </p>
          <FormField
            :reference="'formDocumento'"
            :validate="keyFormValidacaoDocumento"
            :fields="fieldsDocumento"
            @changeFile="onChangeDocumento($event)"
          ></FormField>
        </v-form>
      </template>
    </DialogConfirmation>

    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="socios"
      :loading="carregandoTabela"
      :keyPaginacao="keyPaginacao"
      :itemsPerPage="socios.length"
    >
      <template
        v-slot:top
      >
        <v-toolbar flat>
          <ButtonMain
            text="Adicionar Sócio"
            :primary="true"
            @click.native="abrirDialogFormSocio('cadastro')"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>

      <template v-slot:[`item.administrador`]="{ item }">
        <span>{{ item.administrador ? 'SIM' : 'NÃO' }}</span>
      </template>


      <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain top text="Editar Sócio">
            <ButtonIcon
              class="mx-1"
              icon="pencil"
              :class="'warning'"
              @click.native="abrirDialogFormSocio('edicao', item)"
            />
          </TooltipMain>

          <TooltipMain top text="Adicionar Documento do Sócio">
            <ButtonIcon
              class="mx-1"
              icon="file"
              :class="'success'"
              @click.native="abrirDialoAdicionarDocumento(item)"
            />
          </TooltipMain>

          <TooltipMain top text="Ver Documentos do Sócio">
            <ButtonIcon
              class="mx-1"
              icon="eye"
              :primary="true"
              @click.native="abrirDialogDocumetosSocio(item.arquivos, item.nome)"
            />
          </TooltipMain>
        </v-row>
      </template>
    </CrudTable>

    <v-row>
      <v-col class="text-center mt-5" md="12" order-md="1">
        <div class="footer-pagina">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn class="ml-3" to="/lista-parceiros">
              <v-icon small>mdi-arrow-left-thin </v-icon>
              Voltar
            </v-btn>
          </v-toolbar>
        </div>
      </v-col>
    </v-row>
  </div>

</template>

<script>
  import CrudTable from '@/components/molecules/tables/CrudTable'
  import ButtonIcon from '../../components/atoms/buttons/ButtonIcon'
  import TooltipMain from '../../components/atoms/TooltipMain'
  import ButtonMain from '../../components/atoms/buttons/ButtonMain'
  import TitleH2 from '../../components/atoms/title/TitleH2.vue'
  import FormField from '../../components/atoms/forms/FormField.vue'
  import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
  import parceiroService from '@/services/ParceiroService'

  export default {
    components: {
      CrudTable,
      ButtonIcon,
      TooltipMain,
      ButtonMain,
      TitleH2,
      FormField,
      DialogConfirmation
    },

    data() {
      return {
        keyDialogAdicionarSocio: 0,
        keyDialogAdicionarDocumento: 20,
        keyFormValidacao: 2,
        keyFormValidacaoDocumento: 30,
        exibirDialogAdicionarSocio: false,
        exibirDialogAdicionarDocumento: false,
        carregandoTabela: false,
        dialogDocumentosAberto: false,
        socioSelecionado: null,
        keyPaginacao: 0,
        nomeSocio: "",
        tagDialogSocio: "",
        socios: [],
        listaDocumentosSocio: [],
        headers: [
          { text: 'Nome', value: 'nome', width: 350, sortable: false },
          { text: 'Administrador', value: 'administrador', align: 'center', sortable: false },
          { text: 'Email', value: 'email', align: 'center', sortable: false },
          { text: 'CPF', value: 'cpf', align: 'center', sortable: false },
          { text: 'Telefone', value: 'telefone', align: 'center', sortable: false },
          { text: 'Ações', value: 'acoes', width: 190, sortable: false }
        ],
        fields: {
          nome: {
            label: 'Nome',
            valueField: null,
            value: '',
            cols: 8,
            field: 'InputField',
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          cpf: {
            label: 'CPF',
            valueField: null,
            cols: 4,
            value: '',
            field: 'InputMaskField',
            maskFormat: '###.###.###-##',
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          email: {
            label: 'Email',
            valueField: '',
            cols: 7,
            value: '',
            field: 'InputField',
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          ddd: {
            label: 'DDD',
            valueField: null,
            cols: 2,
            value: '',
            field: 'InputMaskField',
            maskFormat: '##',
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          telefone: {
            label: 'Telefone ',
            valueField: null,
            value: '',
            cols: 3,
            field: 'InputMaskField',
            maskFormat: '#####-####',
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          administrador: {
            label: 'Sócio-Administrador ',
            valueField: false,
            cols: 5,
            field: 'CheckBoxField',
            rules: []
          },
        },
        fieldsDocumento: {
          tipoDocumento: {
            label: 'Tipo do Documento',
            textoNadaEncontrado: 'Todos os tipos de documento já cadastrados',
            valueField: null,
            value: '',
            cols: 12,
            field: 'SelectField',
            items: [],
            keyValue: 'id',
            keyName: 'descricao',
            clearable: false,
            rules: [(value) => !!value || 'Campo obrigatório'],
          },
          documento: {
            icon: 'mdi-file',
            accept: '.jpg, .jpeg, .pdf',
            label: 'Inserir Documento',
            valueField: null,
            value: '',
            cols: 12,
            key: 4,
            field: 'FileInput',
            rules: [(value) => !!value || 'Campo obrigatório'],
          },
        }
      }
    },

    created() {
      this.receberSocios();
    },

    methods: {
      receberSocios() {
        this.carregandoTabela = true;
        parceiroService
          .sociosPorParceiro(this.$route.params.id)
          .then(({ data }) => {
            this.socios = null;
            this.socios = data.map(socio => {
              return {
                ...socio,
                telefone: this.setTelefone(socio.ddd, socio.telefone),
              }
            });
          })
          .catch(() => {})
          .then(() => this.carregandoTabela = false);
      },

      setTelefone(ddd, telefone) {
        if(ddd && telefone) return `(${ddd}) ${telefone}`;
        if(!ddd && telefone) return `${telefone}`;
        return null;
      },

      abrirDialogDocumetosSocio(documentos, nomeSocio) {
        this.nomeSocio = nomeSocio;
        this.listaDocumentosSocio = documentos;
        this.dialogDocumentosAberto = true;
      },

      abrirDialogFormSocio(modo, socio) {
        this.keyDialogAdicionarSocio++;
        this.exibirDialogAdicionarSocio = true;

        if(modo === 'cadastro') {
          this.tagDialogSocio = 'add';
        } else if(modo === 'edicao' && socio) {
          this.tagDialogSocio = 'edit';
          this.socioSelecionado = socio;
          this.popularFormulario(socio);
        }
      },

      popularFormulario(data) {
        this.criandoFormulario = true;

        Object.entries(data).map(([key, value]) => {
          if(this.fields[key]) {
            this.fields[key].valueField = value;
          }
        });
      },

      fecharDialogSocio() {
        this.keyDialogAdicionarSocio++;
        this.exibirDialogAdicionarSocio = false;
        this.socioSelecionado = null;
      },

      verificarSeHaCampoObrigatorioNulo(campos) {
        let campoInvalido = false;
        for(const [key, value] of Object.entries(campos)) {
          const valorCampo = value.value;
          const regras = value.rules;
          let erroCampo = false;

          if(regras && regras.length) {
            for(const regra of regras) {
              if(regra(valorCampo) === 'Campo obrigatório') {
                erroCampo = true;
                break;
              }
            }
          }

          if(erroCampo) {
            campoInvalido = true;
            break;
          };
        }

        return campoInvalido;
      },

      confirmarAcaoDialogSocio() {
        if (this.verificarSeHaCampoObrigatorioNulo(this.fields)) {
          ++this.keyFormValidacao;
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Dados Incompletos',
          });

          return;
        }

        const body = {
          nome: this.fields.nome.value,
          email: this.fields.email.value,
          cpf: this.fields.cpf.value,
          ddd: this.fields.ddd.value,
          telefone: this.fields.telefone.value,
          arquivos: null,
          parceiro: this.$route.params.id,
          administrador: this.fields.administrador.value,
        }

        if(this.tagDialogSocio === 'add') {
          this.adicionarSocio(body);
        }

        else if (this.tagDialogSocio === 'edit') {
          this.editarSocio(body);
        }
      },

      adicionarSocio(body) {
        parceiroService
          .cadastrarSocio(body)
          .then(() => {
            this.fecharDialogSocio();
            this.receberSocios();
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          });
      },

      editarSocio(body) {
        parceiroService
          .editarSocio(this.socioSelecionado.id, body)
          .then(() => {
            this.fecharDialogSocio();
            this.receberSocios();
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          });
      },

      abrirDialoAdicionarDocumento(socio) {
        this.exibirDialogAdicionarDocumento = true;
        this.socioSelecionado = socio;
        this.receberTiposDocumentosSocio(socio);
      },

      fecharDialogDocumento() {
        ++this.keyDialogAdicionarDocumento;
        this.exibirDialogAdicionarDocumento = false;
        this.socioSelecionado = null;

        this.fieldsDocumento.tipoDocumento.value = null;
        this.fieldsDocumento.documento.value = null;
      },

      receberTiposDocumentosSocio(socio) {
        parceiroService
          .tiposDocumentosSocio()
          .then(({ data }) => {
            this.copiaTiposDocumentosSocio = Object.assign([], data);

            if(socio.arquivos.length > 0) {
              socio.arquivos.forEach(anexo => {
                data = this.removerTipoDeAnexo(anexo, data);
              });
            }

            this.fieldsDocumento.tipoDocumento.items = data;
          });
      },

      removerTipoDeAnexo(anexo, tipos) {
        tipos.forEach((tipo) => {
          if(tipo.id === anexo.idTipo) {
            tipos.splice(tipos.indexOf(tipo), 1);
          }
        });

        return tipos;
      },

      onChangeDocumento(evento) {
        this.fieldsDocumento.documento.value = evento.arquivo
        this.fieldsDocumento.documento.valueField = evento.arquivo
      },

      adicionarDocumento() {
        if (this.verificarSeHaCampoObrigatorioNulo(this.fieldsDocumento)) {
          ++this.keyFormValidacaoDocumento;
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Dados Incompletos',
          });

          return;
        }

        const arquivo = this.fieldsDocumento.documento.value;
        const body = {
          socio: this.socioSelecionado.id,
          arquivos: [{
            arquivo: arquivo.split(';base64,')[1],
            descricaoTipo: this.fieldsDocumento.tipoDocumento.items.find(item => item.id === this.fieldsDocumento.tipoDocumento.value).descricao,
            extensao: arquivo.split(';')[0].split('/')[1],
            idTipo: this.fieldsDocumento.tipoDocumento.value,
          }],
        };

        parceiroService
          .cadastrarDocumentoSocio(body)
          .then(() => {
            this.$root.vtoast.show({
              status: 'success',
              title: 'Cadastrado',
              text: 'Documento Cadastrado com sucesso',
            });

            this.fecharDialogDocumento();
            this.receberSocios();
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          });
      }
    },


  }
</script>

<style scoped>

</style>