var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogConfirmation',{attrs:{"width":_vm.tagDialog == 'add' ? 850 : 500,"show":_vm.exibirDialogConfirmacao,"tag":_vm.tagDialog,"title":_vm.tagDialog === 'delete' ? 'Desvincular Área' : 'Vincular Área'},on:{"close":function($event){return _vm.fecharDialog()},"confirm":function($event){return _vm.alterarStatusAreaAtuacao()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.tagDialog === 'add')?_c('div',[_c('v-autocomplete',{staticClass:"mt-5",attrs:{"label":"Áreas do Parceiro","item-text":"descricao","item-value":"idArea","outlined":"","return-object":"","items":_vm.areasNaoVinculadas},model:{value:(_vm.itemSelecionado),callback:function ($$v) {_vm.itemSelecionado=$$v},expression:"itemSelecionado"}})],1):_vm._e(),(_vm.tagDialog === 'activate')?_c('p',{staticClass:"mt-5"},[_vm._v(" Deseja vincular a área "+_vm._s(_vm.itemSelecionado.descricao)+" à este parceiro? ")]):_vm._e(),(_vm.tagDialog === 'delete')?_c('p',{staticClass:"mt-5"},[_vm._v(" Deseja desvincular a área "+_vm._s(_vm.itemSelecionado.descricao)+" deste parceiro? ")]):_vm._e()]},proxy:true}])}),_c('CrudTable',{attrs:{"tabelaKey":"id","headers":_vm.headers,"items":_vm.areasVinculadas,"pagination":_vm.pagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('ButtonMain',{attrs:{"primary":true,"text":"Vincular Área"},nativeOn:{"click":function($event){return _vm.abrirDialogVincularArea()}}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"green","disabled":item.ativo},on:{"click":function($event){_vm.exibirDialogConfirmacao = true
          _vm.tagDialog = 'activate'
          _vm.itemSelecionado = item}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Vincular Área")])])],1),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"red","disabled":!item.ativo},on:{"click":function($event){_vm.exibirDialogConfirmacao = true
          _vm.tagDialog = 'delete'
          _vm.itemSelecionado = item}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Desativar Área")])])],1)]}},{key:"item.descricao",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:item.ativo ? '' : 'area-desviculada'},[_vm._v(_vm._s(item.descricao))])]}}],null,true)}),_c('div',{staticClass:"footer-pagina"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3 mt-5",attrs:{"to":_vm.rotaDeVolta}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left-thin ")]),_vm._v(" Voltar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }